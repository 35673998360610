import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { useInit } from "./hooks";

import "./plugins/flexble";
import vant from "./plugins/vant";

import "./assets/styles/index.less";
import "vant/lib/index.css";
import "@hnw/nw-sdk/lib/styles/index.css"; // 引入样式
import "animate.css";

// import { copyCommand } from './utils/copy';
// copyCommand();

async function testFn() {
  if (process.env.VUE_APP_START === "test") {
    const { default: VConsole } = await import("vconsole");

    const vConsole = new VConsole();
  }
}
testFn();

const app = createApp(App);

app.use(store).use(router).use(vant);

/** 项目初始化 包括sdk初始化 */
useInit(app);

app.mount("#app");
